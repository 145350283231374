import React, { Component } from 'react';
import { graphql, navigate, Link } from 'gatsby';
import styled from 'react-emotion';
import CurrencyFormat from 'react-currency-format';
import queryString from 'query-string';
import moment from 'moment';
import dotenv from 'dotenv';
import DatePicker, { registerLocale } from 'react-datepicker';
import id from 'date-fns/locale/id';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import SanitizedHTML from 'react-sanitized-html';
import ReactPixel from 'react-facebook-pixel';
import { toast } from 'react-toastify';
import Helmet from 'react-helmet';
import Slider from "react-slick";
import ProductCard from '../../../components/product-card';
import Layout from '../../../components/layout';
import Container from '../../../components/container';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

dotenv.config();
const isSSR = typeof window === 'undefined';
if (!isSSR) {
  registerLocale('id', id);
}
// Query result goes to this.props.data
export const query = graphql`
  query {
    settings{
      data{
        blacklist
        simblacklist
      }
    }
    allWifi {
      edges {
        node {
          productId
          title
          price
          duration
          discount_price
          description
          meta_description
          suggestions
          images{url,alt}
          thumbs{url,alt}
          promo
          simcard
          slug
        }
      }
    }
    allWifiLocal {
      edges {
        node {
          productId
          title
          price
          duration
          discount_price
          description
          meta_description
          suggestions
          images{url,alt}
          thumbs{url,alt}
          promo
          simcard
          slug
        }
      }
    }
    allReseller {
      edges {
        node {
          productId
          title
          price
          duration
          discount_price
          description
          meta_description
          suggestions
          images{url,alt}
          thumbs{url,alt}
          promo
          simcard
          slug
          quota
        }
      }
    }
  }
`

const AddToCartButton = styled.a`
  ${tw`rounded py-2 cursor-pointer block w-full text-center`}
  text-decoration: none;
  color: #55bd22;
  border: 1px solid #55bd22;
  &:hover{
    background-color: #e5f3df;
  }
`

const ExpressCheckoutButton = styled.a`
  ${tw`text-white rounded py-2 border-none cursor-pointer block w-full text-center`}
  background-color: #55BD22;
  text-decoration: none;
  border: 1px solid #55bd22;
`

const ProductPhotos = styled.div
  `${tw`block w-full align-top md:inline-block md:w-1/3`}`

const ProductDetails = styled.div
  `${tw`block w-full align-top md:pl-3 md:inline-block md:w-2/3`}`

const Block = styled.div
  `${tw`p-7`}`

const PrimaryImage = styled.img
  `${tw`block w-full border-solid border-black border-1`}`

const Carousel = styled.div`
  ${tw`w-full flex items-stretch mb-6`}
`

const CarouselItem = styled.div`
  ${tw`inline-block w-1/3 border-solid border-black flex flex-col justify-center`}
  margin: 0 5px;
`

const CarouselImage = styled.img`
  ${tw`m-0`}
  min-height:100%;
  min-width: 100%;
  object-fit: cover;
`

const Name = styled.h1
  `${tw`text-left`}`

const Price = styled.div
  `${tw`text-red-dark font-semibold text-base`}
  color: #9c0cc4;
  `

const FieldRow = styled.div`
  ${tw`flex`};
  margin: 0 -4px;
`
const ButtonRow = styled.div`
  ${tw`block md:flex mt-4`}
  margin-left: -4px;
  margin-right: -4px;
`
const InputContainer = styled.div`
  ${tw`w-full`};
  padding: 0 4px;
`

const QuantityInputContainer = styled(InputContainer)`
  ${tw`w-1/5`};
`

const ButtonContainer = styled.div`
${tw`w-full mt-2 md:m-0`};
  padding: 0 4px;
`

const DateLabel = styled.label`
${tw`h-4`};
font-size: 0.65rem;
`

const Label = styled.label
  `${tw`block`}`

const Input = styled.input`
  ${tw`block w-full`}
`
const SubTotal = styled.div`
  ${tw`text-red-dark font-bold text-lg`}
  color: #9c0cc4;
`

const Submit = styled.input
  `${tw`block`}`

const InpDate = styled.span`
  border: solid 1px;
  display:block;
  padding: 2px 5px;
  cursor: pointer;
`
const RecSection=styled.div`
  ${tw`py-3 my-6`};
`
const RecHeader=styled.h2
`${tw`mb-4 mt-2 uppercase text-sm md:text-base`}
font-family: Open sans
`
const PromoLeft=styled.span`
${tw`self-end mb-1 text-sm `};
color:red;
`
const ExampleCustomInput = lesi => {
  return (
    <InpDate
      onClick={lesi.onClick}>
        
      {lesi.value?lesi.value:'Pilih Tanggal Keberangkatan'}
    </InpDate>
  )
}
const ExampleCustomInput2 = lesi => {
  return (
    <InpDate
      onClick={lesi.onClick} style={{backgroundColor:'#eee',cursor:'default'}}>
        
      {lesi.value?lesi.value:'Pilih Tanggal Keberangkatan'}
    </InpDate>
  )
}
class Product extends Component {
  constructor(props) {
    super(props);
    
    this.changeStartDateHandler = this.changeStartDateHandler.bind(this)
    this.changeLastDateHandler = this.changeLastDateHandler.bind(this)
    this.changeQuantityHandler = this.changeQuantityHandler.bind(this)
    this.addToShoppingCart = this.addToShoppingCart.bind(this)
    this.changeFrontImage = this.changeFrontImage.bind(this)
    this.directCheckout = this.directCheckout.bind(this)
  }

  state = {
    today: moment().format('YYYY-MM-DD'),
    startDate: moment().format('YYYY-MM-DD'),
    lastDate: moment().add(3, 'days').format('YYYY-MM-DD'),
    totalDays: 0,
    totalCost: 0,
    quantity: 1,
    id: '',
    description: '',meta_description:'',
    discount_price: 0,
    frontImage: '',
    images: [],
    price: 0,
    duration: 0,
    sku: "",
    thumbs: [],
    title: "",
    category: null,
    promo:0,
    slug:'',
  }

  componentDidMount() {
    let category = 'wifi';
    ReactPixel.init(process.env.GATSBY_PIXEL_ID)
    ReactPixel.pageView()
    const referralCode = this.props.location ? queryString.parse(this.props.location.search).ref_id : '';
    if (referralCode) {
      this.props.changeReferralCode(referralCode);
    }
    console.log(this.props.pageContext)
    const productId = this.props.location ? (this.props.pageContext.productId!==undefined ? this.props.pageContext.productId : queryString.parse(this.props.location.search).id):''

    let product = this.props.data.allWifi.edges.find((product) => {
      return product.node.productId == productId;
    });
    if (!product) {
      product = this.props.data.allWifiLocal.edges.find((product) => {
        return product.node.productId == productId;
      });
    }
    //date item received after today
    var adder = 3;
    
    this.setState({startDate:new Date(moment().add(adder,'days').format('YYYY-MM-DD'))})
    // If not found in Wifi node find in Ticket node
    
    if (!product) {
      product = this.props.data.allReseller.edges.find((product) => {
        return product.node.productId == productId;
      });
      category='reseller';
    }
    console.log(this.state.category)
      
    // if (!product) {
    //   product = this.props.data.allSimcard.edges.find((product) => {
    //     return product.node.productId == productId;
    //   }); 
    //   this.setState({ category: 'simcard' });
    // }
    // If not found in Ticket node then go to home
    if (!product) {
      console.log(this.props.pageContext);
    } else {
      if (!product.node.simcard) {
        while (this.props.data.settings.data.blacklist.find(date=>moment(date).isSame(moment().add(adder,'days').format('YYYY-MM-DD')))!=undefined){
          adder++;
        }
      } else{
        while (this.props.data.settings.data.simblacklist.find(date=>moment(date).isSame(moment().add(adder,'days').format('YYYY-MM-DD')))!=undefined){
          adder++;
        }
      }
      var suggestions = [];
      product.node.suggestions.forEach(id=>{
        let suggest = this.props.data.allWifi.edges.find(product=>{
          return product.node.productId == parseInt(id);
        })
        if (!suggest){
          suggest = this.props.data.allReseller.edges.find(product=>{
            return product.node.productId == parseInt(id);
          })
        }
        // if (!suggest){
        //   suggest = this.props.data.allSimcard.edges.find(product=>{
        //     return product.node.productId == parseInt(id);
        //   })
        // };
        if (suggest){
          suggestions.push(suggest)
        }
      })
      
      this.setState({
        id: product.node.productId,
        description: product.node.description,
        meta_description:product.node.meta_description,
        discount_price: product.node.discount_price,
        images: product.node.images,
        frontImage: product.node.images[0],
        price: product.node.price,
        totalCost: product.node.discount_price,
        duration: product.node.duration,
        totalDays: product.node.duration,
        promo: product.node.promo,
        sku: product.node.sku,
        thumbs: product.node.thumbs,
        title: product.node.title,
        slug: product.node.slug,
        suggestions,
        category,
        startDate:moment().add(adder,'days').format('YYYY-MM-DD'),
        lastDate: moment().add(adder + product.node.duration - 1, 'days').format('YYYY-MM-DD'),
        quota:product.node.quota?product.node.quota:0,
      });
    }
  }

  changeQuantityHandler(event) {
    if (event.target.value>0 && (this.state.category=='reseller'||this.state.category=='simcard')){
      this.setState({
        quantity:event.target.value,
        totalDays:event.target.value,
        totalCost: this.state.discount_price * parseInt(event.target.value)
      });
    //   let ld = moment(this.state.startDate).add(parseInt(event.target.value) - 1, 'days').add(2,'seconds').endOf;
    //   if (this.props.data.settings.data.blacklist.some(date=> moment(date).isBetween(this.state.startDate,ld))){
    //     if (this.state.category=='wifi'){
    //       alert('Device tidak tersedia pada tanggal tersebut, silahkan pilih tanggal lain');
    //     } else {
    //       this.setState({
    //         quantity: parseInt(event.target.value),
    //         totalCost: this.state.discount_price * parseInt(event.target.value),
    //       })
    //     }
    //   } else{
    //     this.setState({
    //       quantity: (this.state.duration>1)?Math.ceil(parseInt(event.target.value)/this.state.duration):parseInt(event.target.value),
    //       totalCost: this.state.discount_price * ((this.state.duration>1)?Math.ceil(parseInt(event.target.value)/this.state.duration):parseInt(event.target.value)),
    //       lastDate: ld.format('YYYY-MM-DD')
    //     }, () => {
    //       const startDate = moment(this.state.startDate);
    //       const lastDate = moment(this.state.lastDate);
    //       this.setState({
    //         totalDays: moment.duration(lastDate.diff(startDate)).as('days')
    //       })
    //     });
    //   }
    }
  }

  changeStartDateHandler(event) {
    if (new Date(moment(this.state.today)) <= event){
      let sd = moment(event).startOf('day');
      let ld = moment(event).add((this.state.category=='wifi'?this.state.duration*this.state.quantity-1:this.state.duration-1),'days').endOf('day');
      if ((this.state.category=='wifi' && this.props.data.settings.data.blacklist.some(date=> moment(date).isBetween(sd,ld))) ||
        (this.state.category=='simcard' && this.props.data.settings.data.simblacklist.some(date=> moment(date).isSame(sd)))
      ){
        alert('Device tidak tersedia pada tanggal tersebut, silahkan pilih tanggal lain');
        return
      } else {
        this.setState({ 
          startDate: sd.format('YYYY-MM-DD'),
          lastDate:ld.format('YYYY-MM-DD'),
          // quantity:1,
          totalCost: this.state.discount_price,
          totalDays: this.state.duration
        });
      }
    }
  }

  changeLastDateHandler(event) {
    let tld = moment(event).endOf('day');
    let sd = moment(this.state.startDate).startOf('day');
    let dur = tld.diff(sd,'days')+1;
    let qty = Math.ceil(dur/this.state.duration);
    let totdays = qty*this.state.duration;
    let ld = moment(this.state.startDate).add(totdays - 1 ,'days').endOf('day');
    if (this.props.data.settings.data.blacklist.some(date=> moment(date).isBetween(sd,ld))){
      alert('Device tidak tersedia pada tanggal tersebut, silahkan pilih tanggal lain');
        return
    } else {
      this.setState({ 
        lastDate:ld.format('YYYY-MM-DD'),
        quantity:qty,
        totalDays: totdays,
        totalCost: qty*this.state.discount_price
      });
    }
  }

  addToShoppingCart() {
    if ((this.state.category=='wifi' && this.props.data.settings.data.blacklist.some(date=> moment(date).isBetween(this.state.startDate,this.state.lastDate)))||
      (this.state.category=='simcard' && this.props.data.settings.data.simblacklist.some(date=> moment(date).isSame(this.state.startDate)))
    ){
      alert('Device tidak tersedia pada tanggal tersebut, silahkan pilih tanggal lain');
      return
    }
    if (this.props.shoppingCart.find((p) => p.key === `${this.state.id}_${this.state.startDate}_${this.state.lastDate}`)) {
      this.props.addToast('Produk telah ditambah ke dalam keranjang belanja sebelumnya', 'warning');
    } else {
      if (this.props.shoppingCart.find(p=> p.promo===1) || (this.state.promo && this.props.shoppingCart.length)){
        if (window.confirm('Produk promo tidak bisa digabung dengan produk lain dalam satu transaksi, hapus produk dalam keranjang dan tambahkan produk ini?')){
          this.props.clearShoppingCart();
        } else {
          return
        }
      }
      if ((this.props.shoppingCart.find(p=> p.type==='simcard')&&this.state.category!='simcard')||(this.props.shoppingCart.find(p=> p.type!=='simcard')&&this.state.category=='simcard')){
        if (window.confirm('Produk SIM Card tidak bisa digabung dengan produk selain SIM Card dalam satu transaksi, hapus produk dalam keranjang dan tambahkan produk ini?')){
          this.props.clearShoppingCart();
        } else {
          return
        }
      }
      const order = {
        id: this.state.id,
        key: `${this.state.id}_${this.state.startDate}_${this.state.lastDate}`,
        startDate: this.state.startDate,
        lastDate: this.state.lastDate,
        totalDays: this.state.totalDays,
        quantity: this.state.quantity,
        duration: this.state.duration,
        title: this.state.title,
        price: this.state.price,
        promo: this.state.promo,
        discountPrice: this.state.discount_price,
        totalCost: this.state.totalCost,
        images: this.state.images,
        thumbs: this.state.thumbs,
        type: this.state.category
      }
      console.log({order})
      this.props.addToShoppingCart(order);
      this.props.addToast('Produk telah ditambah ke dalam keranjang belanja', 'info');
      ReactPixel.track('AddToCart')
      
    }
    let newNav = this.state.slug.substring(0,this.state.slug.substring(0,this.state.slug.length - 1).lastIndexOf('/')+1);
    navigate(newNav);
  }

  directCheckout() {
    if ((this.state.category=='wifi' && this.props.data.settings.data.blacklist.some(date=> moment(date).isBetween(this.state.startDate,this.state.lastDate)))||
      (this.state.category=='simcard' && this.props.data.settings.data.simblacklist.some(date=> moment(date).isSame(this.state.startDate)))
    ){
      alert('Device tidak tersedia pada tanggal tersebut, silahkan pilih tanggal lain');
      return
    }
    if (this.props.shoppingCart.find((p) => p.key === `${this.state.id}_${this.state.startDate}_${this.state.lastDate}`)) {
      this.props.addToast('Produk telah ditambah ke dalam keranjang belanja sebelumnya', 'warning');
    } else {
      if (this.props.shoppingCart.find(p=> p.promo===1) || (this.state.promo && this.props.shoppingCart.length)){
        if (window.confirm('Produk promo tidak bisa digabung dengan produk lain dalam satu transaksi, hapus produk dalam keranjang dan tambahkan produk ini?')){
          this.props.clearShoppingCart();
        } else {
          return
        }
      }
      if ((this.props.shoppingCart.find(p=> p.type==='simcard')&&this.state.category!='simcard')||(this.props.shoppingCart.find(p=> p.type!=='simcard')&&this.state.category=='simcard')){
        if (window.confirm('Produk SIM Card tidak bisa digabung dengan produk selain SIM Card dalam satu transaksi, hapus produk dalam keranjang dan tambahkan produk ini?')){
          this.props.clearShoppingCart();
        } else {
          return
        }
      }
      const order = {
        id: this.state.id,
        key: `${this.state.id}_${this.state.startDate}_${this.state.lastDate}`,
        startDate: this.state.startDate,
        lastDate: this.state.lastDate,
        totalDays: this.state.totalDays,
        quantity: this.state.quantity,
        duration: this.state.duration,
        title: this.state.title,
        price: this.state.price,
        promo: this.state.promo,
        discountPrice: this.state.discount_price,
        totalCost: this.state.totalCost,
        images: this.state.images,
        thumbs: this.state.thumbs,
        type: this.state.category
      }
      this.props.addToShoppingCart(order);
    }
    navigate('/cart');
  }

  changeFrontImage(image) {
    this.setState({
      frontImage: image
    });
  }
  
  render() {
    const bdates = this.props.data.settings.data.blacklist.map((d)=>new Date(d))
    const bsimdates = this.props.data.settings.data.simblacklist.map((d)=>new Date(d))
    var recommendSettings = {
      infinite: false,
      speed: 500,
      autoplay: true,
      autoplaySpeed:5000,
      slidesToShow:4,
      lazyLoad: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 2
          }
        }
      ],
    };
    return (
      <Layout>
        <Helmet>
          <title>{this.props.pageContext.title + ' | Wifi Republic'}</title>
          <link rel="alternate" href={this.props.location.href} hrefLang="id" />
          <link rel="canonical" href={this.props.location.href} />
          <meta name="title" content={this.props.pageContext.title + ' | Wifi Republic'} />
          <meta name="description" content={ this.props.pageContext.description} />
          <meta name="thumbnail" content={this.props.pageContext.thumb} />
          <meta name="og:title" content={this.props.pageContext.title + ' | Wifi Republic'} />
          <meta name="og:type" content="website" />
          <meta name="og:description" content={ this.props.pageContext.description} />
          <meta name="og:image" content={this.props.pageContext.thumb} />
          <meta name="og:url" content={this.props.location.href} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={this.props.pageContext.title + ' | Wifi Republic'} />
          <meta name="twitter:description" content={ this.props.pageContext.description} />
          <meta name="twitter:image" content={this.props.pageContext.thumb} />
          <meta name="twitter:url" content={this.props.location.href} />
          <meta name="twitter:site" content="@wifirepublic" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/", 
              "@type": "Product", 
              "name": this.props.pageContext.title,
              "image": this.props.pageContext.thumb,
              "description": this.props.pageContext.description,
              "brand": "Wifirepublic",
              "offers": {
                "@type": "Offer",
                "url": this.props.location.href,
                "priceCurrency": "IDR",
                "price": this.props.pageContext.price,
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            })}
          </script>
          <script src="/jquery.min.js" />
          <script src="/theme.js" />
          <link rel="stylesheet" href="/theme2.css" />
          <link rel="stylesheet" href="/gilroy.css" />
        </Helmet>
        <Container>
          <ProductPhotos>
            <Block>
              <PrimaryImage src={this.state.frontImage?this.state.frontImage.url:this.props.pageContext.thumb} alt={this.state.frontImage?this.state.frontImage.alt:this.props.pageContext.thumb_alt}></PrimaryImage>
              <Carousel>
                {
                  this.state.images.map((image,idx) => (
                    <CarouselItem key={'carr_'+idx}>
                      <CarouselImage src={image.url} onClick={() => this.changeFrontImage(image)} alt={image.alt}/>
                    </CarouselItem>
                  ))
                }
              </Carousel>
            </Block>
          </ProductPhotos>
          <ProductDetails>
            <Name>{this.props.pageContext.title}</Name>
            <Price>
              {
                (this.state.price !== this.state.discount_price)
                ? (
                  <CurrencyFormat
                    value={this.state.price}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'Rp '}
                    style={{ textDecoration: 'line-through', marginRight: '10px' }}
                  />
                ) : null
              }
              <CurrencyFormat
                value={this.state.discount_price}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'Rp '}
              />
            </Price>
            <div>
              <SanitizedHTML
                allowedTags= {false}
                allowedAttributes= {false}
                html={
                  this.props.pageContext.pgcontent
                }
              />
            </div>
            <form onSubmit={this.addToShoppingCart}>
              <FieldRow>
                { this.state.category === 'reseller' ? null
                  : (
                    <>
                      <InputContainer>
                        <DateLabel htmlFor="start">Tanggal Aktivasi</DateLabel>
                        <DatePicker
                          locale={isSSR ? 'en' : "id"}
                          customInput={<ExampleCustomInput />}
                          selected={new Date(this.state.startDate)}
                          minDate={new Date(moment(this.state.today).format('YYYY-MM-DD'))}
                          excludeDates={this.state.category === 'wifi' ? bdates : bsimdates}
                          onChange={this.changeStartDateHandler}
                          dateFormat="dd MMMM yyyy"
                          popperPlacement="bottom-start"
                          required/>
                      </InputContainer>
                      <InputContainer>
                        <DateLabel htmlFor="finish">Aktif Hingga</DateLabel>
                        <DatePicker
                          locale={isSSR ? 'en' : "id"}
                          customInput={this.state.category=='simcard'?<ExampleCustomInput2 />:<ExampleCustomInput />}
                          selected={new Date(this.state.lastDate)}
                          minDate={new Date(this.state.startDate)}
                          excludeDates={this.state.category === 'wifi' ? bdates : bsimdates}
                          onChange={this.changeLastDateHandler}
                          dateFormat="dd MMMM yyyy"
                          popperPlacement="bottom-end"
                          popperClassName="dateEndPopper"
                          disabled={this.state.category=='simcard'}
                          required/>
                        
                      </InputContainer>
                    </>
                  )
                }
                <QuantityInputContainer>
                  <DateLabel htmlFor="quantity">{this.state.category === 'wifi' ? 'Durasi' : 'Jumlah'}</DateLabel>
                  <Input type="number" style={{ display: 'block',paddingLeft:'.5em' }} value={this.state.category=='wifi'?(this.state.quantity*this.state.duration):this.state.quantity}
                   onChange={this.changeQuantityHandler} disabled={this.state.category==='wifi'?true:false} />
                </QuantityInputContainer>
                {this.state.category=='reseller'?
                  <PromoLeft>sisa {((23 - moment().hour())*2) + this.state.quota} lagi</PromoLeft>
                :null}
              </FieldRow>
              {/* {this.state.category=='reseller'?null:
                <small>ingin lebih cepat dari tanggal ini? Silahkan Chat ke CS kami</small>
              } */}
              <FieldRow>
                <InputContainer>
                  <DateLabel htmlFor="finish">Subtotal</DateLabel>
                  <SubTotal>
                    <CurrencyFormat
                      value={this.state.totalCost}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'Rp '}
                    />
                  </SubTotal>
                </InputContainer>
              </FieldRow>
              <ButtonRow>
                <ButtonContainer>
                  <ExpressCheckoutButton href onClick={this.directCheckout}>Lanjutkan Transaksi</ExpressCheckoutButton>
                </ButtonContainer>
                <ButtonContainer>
                  <AddToCartButton href onClick={this.addToShoppingCart}>
                    {
                      this.state.category === 'wifi' ? (
                        'Tambah Destinasi'
                      ) : (
                        'Tambah ke Keranjang'
                      )
                    }
                  </AddToCartButton>
                </ButtonContainer>
              </ButtonRow>
            </form>
          </ProductDetails>
          {(this.state.suggestions && this.state.suggestions.length) ?
          <RecSection>
            <RecHeader>Pilihan lainnya untuk kamu</RecHeader>
            <Slider {...recommendSettings}>
              {this.state.suggestions.map((item,idx) => 
                <ProductCard key={'sugg_'+idx}
                id={item.node.productId}
                image={item.node.thumbs[0]}
                productName={item.node.title}
                discountPrice={item.node.discount_price}
                currentPrice={item.node.price}
                productDetail={item.node}
                lgth='full'
                slug={item.node.slug}/>
              )}
            </Slider>
          </RecSection>:null}
        </Container>
      </Layout>
    );
  }
}


const mapStateToProps = state => {
  return {
    shoppingCart: state.shoppingCart
  };
}

const mapDispatchToProps = dispatch => {
  return {
    clearShoppingCart: () => dispatch({ type: 'CLEAR_CART' }),
    addToShoppingCart: (order) => dispatch({
      type: 'ADD_PRODUCT',
      order
    }),
    addToast: (message, method) => dispatch({ type: `ADD_TOAST`, message, method }),
    changeReferralCode: (code) => dispatch({ type: 'CHANGE_REFERRAL_CODE', code })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);